<template>
  <div>
    <SideBarFilter
      :filter="filter"
      ref="sidebarFilterService"
      placeholder="Service"
      @searchAll="searchAll"
      @clearFilter="clearFilter"
      @clearForm="clearForm"
      :hideStatusFilter="false"
      :hideSearchBar="true"
      handleSideBar
    >
      <template v-slot:filter-option>
        <div class="my-3">
          <InputDropdownCheckbox
            ref="inputDayjustround"
            name="brand"
            textFloat="Brand"
            :value="filter.brandIds"
            :options="optionBrand"
            :valueSelectedAll="checkBrandAll"
            valueField="id"
            textField="name"
            @onDataChangeCheckAll="(val) => (checkBrandAll = val)"
            @onDataChange="(val) => (filter.brandIds = val)"
          />
        </div>
        <div class="my-3">
          <InputDropdownCheckbox
            ref="inputDayjustround"
            name="category"
            textFloat="Category"
            :value="filter.categoryIds"
            :options="optionCategory"
            :valueSelectedAll="checkCategoryAll"
            valueField="id"
            textField="name"
            @onDataChangeCheckAll="(val) => (checkCategoryAll = val)"
            @onDataChange="(val) => (filter.categoryIds = val)"
          />
        </div>
        <!-- hide for deploy-->
        <!-- <div class="my-3">
          <InputSelect
            title="Display"
            name="Display"
            class="mt-2"
            v-model="filter.isDisplay"
            v-bind:options="optionIsDisplay"
            valueField="value"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select Status --
              </b-form-select-option>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </InputSelect>
        </div> -->
        <!-- hide for deploy-->
        <div class="my-3">
          <InputSelect
            title="Active"
            name="Active"
            class="mt-2"
            v-model="filter.previewOrPublish"
            v-bind:options="optionPreviewOrPublish"
            valueField="value"
            textField="name"
          >
            <template v-slot:option-first>
              <b-form-select-option value="" disabled
                >-- Select PreviewOrPublish --
              </b-form-select-option>
              <b-form-select-option :value="null">All</b-form-select-option>
            </template>
          </InputSelect>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox.vue";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  name: "ServiceFilter",
  components: { InputDropdownCheckbox, InputSelect },
  props: {
    visible: {
      type: Boolean,
      required: false,
    },
  },
  watch: {
    visible(val) {
      if (val === true) {
        this.fetchOption();
        this.$refs.sidebarFilterService.show();
      } else {
        this.$refs.sidebarFilterService.hide();
      }
    },
  },
  data() {
    return {
      checkBrandAll: false,
      checkCategoryAll: false,
      filter: {
        brandIds: [],
        categoryIds: [],
        isDisplay: null,
        previewOrPublish: null,
      },
      optionBrand: [],
      optionCategory: [],
      optionPreviewOrPublish: [
        { value: true, name: "Active" },
        { value: false, name: "InActive" },
      ],
      optionIsDisplay: [
        { value: true, name: "Display" },
        { value: false, name: "Non Display" },
      ],
    };
  },
  methods: {
    async getOptionBrand() {
      const respone = await this.$services.master.getOptionBrand();
      this.optionBrand = respone.detail;
    },
    async getOptionCategory() {
      const respone = await this.$services.master.getOptionCategory();
      this.optionCategory = respone.detail;
    },
    fetchOption() {
      this.getOptionBrand();
      this.getOptionCategory();
    },
    searchAll() {
      this.$emit("searchFilter", this.filter);
    },
    clearFilter() {
      this.filter = {
        brandIds: [],
        categoryIds: [],
        isDisplay: null,
        previewOrPublish: null,
      };
      this.$emit("searchFilter", this.filter);
    },
    clearForm() {
      this.$emit("closeFilter");
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-table
      responsive
      striped
      hover
      :fields="fields"
      :items="items"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
    >
      <template #cell(id)="{ item }">
        <p
          class="tw-cursor-pointer tw-underline tw-mt-2 tw-text-bold"
          @click="editService(item)"
        >
          {{ item.id }}
        </p>
      </template>
      <template #cell(eventGroupImageUrlShow)="{ item }">
        <div class="container">
          <img class="render-image" :src="item.eventGroupImageUrlShow" />
        </div>
      </template>
      <template #cell(previewOrPublish)="{ item }">
        <p
          class="m-0"
          :class="item.previewOrPublish == true ? 'color-green' : 'color-red'"
        >
          {{ item.previewOrPublish == true ? "Active" : "Inactive" }}
        </p>
      </template>
      <template v-slot:cell(actions)="{ item }">
        <div class="container">
          <font-awesome-icon
            class="mr-4 pointer"
            icon="pen"
            @click="editService(item)"
          />
          <b-form-checkbox
            @change="onChangeStatus(item)"
            v-model="item.previewOrPublish"
            class="pointer"
            switch
            :value="true"
            :unchecked-value="false"
          />
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template></b-table
    >
    <Pagination
      @handleChangeTake="handleChangeTake"
      :pageOptions="pageOptions"
      :rows="rows"
      :filter="filter"
      @pagination="pagination"
    />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
export default {
  name: "ServiceTable",
  components: { Pagination },
  props: {
    filter: {
      required: false,
      type: Object,
    },
    pageOptions: {
      required: true,
      type: Array,
    },
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
  },
  methods: {
    handleChangeTake(value) {
      this.filter.pages = 1;
      this.filter.page = value;
      this.$emit("filterPage", this.filter);
    },
    pagination(page) {
      this.filter.pages = page;
      this.$emit("filterPage", this.filter);
    },
    editService(val) {
      const { id } = val;

      this.$emit("editService", id);
    },
    onChangeStatus(val) {
      this.$emit("onChangeStatus", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.render-image {
  margin: auto;
  display: block;
  max-width: 200px;
  max-height: 95px;
  width: auto;
  height: auto;
  text-align: center;
}
.container {
  min-height: 100px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.color-red {
  color: #dc3545;
}

.color-green {
  color: #28a745;
}
</style>

<template>
  <div>
    <b-row class="tw-mb-2">
      <b-col cols="2">
        <p class="text-header">Service</p>
      </b-col>
      <b-col cols="10">
        <div class="tw-flex tw-w-full tw-justify-end">
          <div class="g-form">
            <div class="input">
              <b-form-input
                v-model="filter.keyword"
                placeholder="Service"
                @focus="iconSearch = true"
                @blur="iconSearch = false"
                @keyup.enter="getEventGroupList"
              ></b-form-input>
              <b-button
                class="search"
                :class="iconSearch ? 'active-border' : ''"
                @click="getEventGroupList"
              >
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-button>
            </div>
          </div>
          <b-button class="btn-filter mr-2" @click="onClickOpenSideBar">
            <span class="d-none d-md-block">
              <span class="">Filter</span>
            </span>
          </b-button>
          <b-button
            class="btn-filter mr-2"
            @click="onClickCreate"
            v-if="visibleBtnCreate"
          >
            <!--  -->
            <span class="d-none d-md-block">
              <span class="">Create</span>
            </span>
          </b-button>
          <b-button
            class="btn-filter"
            v-if="visibleBtnExportLog"
            @click="onClickExportActivityLog"
          >
            <!--  -->
            <span class="d-none d-md-block">
              <b-spinner
                label="Spinning"
                class=""
                small
                v-if="loading"
              ></b-spinner>
              <span class="" v-else>ExportLogs</span>
            </span>
          </b-button>
        </div>
      </b-col>
    </b-row>
    <Table
      :items="items"
      :fields="fields"
      :isBusy="isBusy"
      :rows="rows"
      :filter="filter"
      @editService="editService"
      :pageOptions="pageOptions"
      @filterPage="filterPage"
      @onChangeStatus="onChangeStatus"
    />
    <FilterService
      :visible="visible"
      @closeFilter="closeFilter"
      @searchFilter="searchFilter"
    />
    <ModalAlertText :textModal="textModal" :arrModal="[]" />
  </div>
</template>

<script>
import Table from "./component/table.vue";
import ModalAlertText from "@/components/modal/ModalAlertText.vue";
import FilterService from "./component/filterService.vue";
export default {
  name: "ServicePage",
  components: { Table, FilterService, ModalAlertText },
  data() {
    return {
      // state toggle
      iconSearch: false,
      visible: false,

      //state table
      rows: 0,
      isBusy: false,
      items: [],
      fields: [
        {
          key: "id",
          label: "Id",
          class: "w-50px text-nowrap",
        },
        {
          key: "eventGroupImageUrlShow",
          label: "Image",
          class: "w-100px text-nowrap",
        },
        {
          key: "eventGroupName",
          label: "EventGroup Name",
          class: "w-200px text-nowrap",
        },
        {
          key: "categoryName",
          label: "Service Category",
          class: "w-100px",
        },
        {
          key: "brandName",
          label: "Brand",
          class: "w-50px text-nowrap",
        },
        {
          key: "previewOrPublish",
          label: "Status",
          class: "w-50px text-nowrap",
        },
        { key: "actions", label: "Action", class: "w-100px text-nowrap" },
      ],

      filter: {
        page: 1,
        take: 5,
        keyword: "",
        categoryIds: [],
        brandIds: [],
        isDisplay: null,
        previewOrPublish: null,
      },

      pageOptions: [
        { value: 5, text: "5 / page" },
        { value: 10, text: "10 / page" },
        { value: 15, text: "15 / page" },
        { value: 20, text: "20 / page" },
      ],
      levelCreateService: 12,
      levelExportLog: 15,
      loading: false,
      textModal: "",
    };
  },
  computed: {
    visibleBtnCreate() {
      return !!this.$store.state.permission.Permission.find(
        (e) => e.permissionMenuId === this.levelCreateService
      );
    },
    visibleBtnExportLog() {
      return !!this.$store.state.permission.Permission.find(
        (e) => e.permissionMenuId === this.levelExportLog
      );
    },
  },
  mounted() {
    this.getEventGroupList();
  },
  methods: {
    async onClickExportActivityLog() {
      this.loading = true;
      const respone = await this.$services.eventgroup.ExportActivityLog();
      var date = new Date();
      this.resolveAndDownloadBlob(
        respone,
        `activityLog-${this.$moment(date).format("YYYY-MM-DD")}`
      );
      this.loading = false;
    },
    async getEventGroupList() {
      this.isBusy = true;
      const respone = await this.$services.eventgroup.getEventGroupList(
        this.filter
      );
      if (respone.result === 1) {
        const { data, count } = respone.detail;
        this.items = data;
        this.rows = count;
      }
      this.isBusy = false;
    },
    async editService(id) {
      this.$router.push(`/service/detail/${id}`);
    },
    async onChangeStatus(val) {
      const { id, previewOrPublish } = val;
      const respone = await this.$services.eventgroup.savePublish(
        id,
        previewOrPublish
      );
      if (respone.result === 0) {
        this.getEventGroupList();
      }

      this.openModalAlertText(respone.message);
    },
    filterPage(obj) {
      let { pages } = obj;
      this.filter.page = pages;
      this.getEventGroupList();
    },
    onClickCreate() {
      this.$router.push("/createService");
    },
    onClickOpenSideBar() {
      this.visible = true;
    },
    closeFilter() {
      this.visible = false;
    },
    searchFilter(val) {
      const { brandIds, categoryIds, previewOrPublish, isDisplay, isActive } =
        val;
      this.filter.brandIds = brandIds;
      this.filter.categoryIds = categoryIds;
      this.filter.previewOrPublish = previewOrPublish;
      this.filter.isDisplay = isDisplay;
      this.filter.isActive = isActive;

      this.getEventGroupList();
      this.visible = false;
    },
    resolveAndDownloadBlob(v, filename) {
      return this.$services.utils.resolveAndDownloadBlob(v, filename);
    },
    openModalAlertText(text) {
      this.textModal = text;
      this.$bvModal.show("modal-alert-text");
    },
  },
};
</script>

<style lang="scss" scoped>
.g-form {
  width: 100%;
  margin-right: 10px;
}
.text-header {
  color: #092d53;
  font-weight: 600;
  font-size: 20px;
  text-transform: uppercase;
}
</style>
